import React from 'react';
import { Badge } from 'antd';
import { BellOutlined } from '@ant-design/icons';

const NotificationIcon = ({count}) => {
  return (
    <Badge count={count} offset={[10, 0]}>
      <BellOutlined style={{ fontSize: '24px', color: '#08c' }} />
    </Badge>
  );
};

export default NotificationIcon;
